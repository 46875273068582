/* lato-100 - latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 100;
	src: url('/resources/libraries/webfonts/lato/lato-v16-latin-100.eot'); /* IE9 Compat Modes */
	src: local('Lato Hairline'), local('Lato-Hairline'),
	url('/resources/libraries/webfonts/lato/lato-v16-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-100.woff') format('woff'), /* Modern Browsers */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-100.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-300 - latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	src: url('/resources/libraries/webfonts/lato/lato-v16-latin-300.eot'); /* IE9 Compat Modes */
	src: local('Lato Light'), local('Lato-Light'),
	url('/resources/libraries/webfonts/lato/lato-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-300.woff') format('woff'), /* Modern Browsers */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-regular - latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: url('/resources/libraries/webfonts/lato/lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Lato Regular'), local('Lato-Regular'),
	url('/resources/libraries/webfonts/lato/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src: url('/resources/libraries/webfonts/lato/lato-v16-latin-700.eot'); /* IE9 Compat Modes */
	src: local('Lato Bold'), local('Lato-Bold'),
	url('/resources/libraries/webfonts/lato/lato-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-700.woff') format('woff'), /* Modern Browsers */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/resources/libraries/webfonts/lato/lato-v16-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}

/* rajdhani-regular - latin */
@font-face {
	font-family: 'Rajdhani';
	font-style: normal;
	font-weight: 400;
	src: url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Rajdhani Regular'), local('Rajdhani-Regular'),
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-regular.svg#Rajdhani') format('svg'); /* Legacy iOS */
}

/* rajdhani-500 - latin */
@font-face {
	font-family: 'Rajdhani';
	font-style: normal;
	font-weight: 500;
	src: url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-500.eot'); /* IE9 Compat Modes */
	src: local('Rajdhani Medium'), local('Rajdhani-Medium'),
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-500.woff') format('woff'), /* Modern Browsers */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-500.svg#Rajdhani') format('svg'); /* Legacy iOS */
}

/* rajdhani-600 - latin */
@font-face {
	font-family: 'Rajdhani';
	font-style: normal;
	font-weight: 600;
	src: url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-600.eot'); /* IE9 Compat Modes */
	src: local('Rajdhani SemiBold'), local('Rajdhani-SemiBold'),
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-600.woff') format('woff'), /* Modern Browsers */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-600.svg#Rajdhani') format('svg'); /* Legacy iOS */
}

/* rajdhani-700 - latin */
@font-face {
	font-family: 'Rajdhani';
	font-style: normal;
	font-weight: 700;
	src: url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-700.eot'); /* IE9 Compat Modes */
	src: local('Rajdhani Bold'), local('Rajdhani-Bold'),
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-700.woff') format('woff'), /* Modern Browsers */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/resources/libraries/webfonts/rajdhani/rajdhani-v10-latin-700.svg#Rajdhani') format('svg'); /* Legacy iOS */
}

$main-primary:#EC7A24;
$main-primary-dark:darken($main-primary, 10);
$main-primary-darker:darken($main-primary, 20);
$main-primary-light:lighten($main-primary, 55);
$primary-color:$main-primary;
// enzymedica secondary - grey
$main-secondary:#E9E9E9;
$main-secondary-dark:darken($main-secondary, 10);
$main-secondary-darker:darken($main-secondary, 20);
$secondary-color:$main-secondary;
// clean white
$main-bgc-primary:white;
$main-bgc-primary-dark:darken($main-bgc-primary, 10);
$main-bgc-primary-darker:darken($main-bgc-primary, 20);
// light grey
$main-bgc-secondary:#E9E9E9;
$main-bgc-secondary-dark:darken($main-bgc-secondary, 10);
$main-bgc-secondary-darker:darken($main-bgc-secondary, 20);
// black
$main-font-color-primary:#EC7A24;
$main-font-color-primary-hover:$main-secondary;
// grey
$main-font-color-secondary:black;
$main-font-color-secondary-hover:$main-primary;
$global-radius:3px;

@import "../../../../modules_css/all_libs";
@import "../../../../modules_css/all_modules";

@include block( black, #eee );
@include pagination( $secondary-color );

// OFF CANVAS WIDTH
$offcanvas_size_small:70%;
$offcanvas_size_medium:50%;
// FOOTER HEIGHT
$footer_height_large:60px;
$footer_height_medium:118px;
$footer_height_small:136px;
////// MIXINS //////
@mixin display-flex() {
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
}
@mixin flex-direction($dir) {
	$dir:row !default;
	-webkit-flex-direction:$dir;
		-ms-flex-direction:$dir; // (???)
	flex-direction:$dir;
}
@mixin flex-wrap($wrap) {
	$wrap:wrap !default;
	-webkit-flex-wrap:$wrap;
	-ms-flex-wrap:$wrap;
	flex-wrap:$wrap;
}
@mixin flex-justify($just) {
	$just:space-between !default;
	-webkit-justify-content:$just;
	// "flex-start" => "start"
	@if $just == flex-start {
		-ms-flex-pack:start;
	// "flex-end" => "end"
	 } @else if $just == flex-end {
		-ms-flex-pack:end;
	// "center" => "center"
	 } @else if $just == center {
		-ms-flex-pack:center;
	// "space-between" => "justify"
	 } @else if $just == space-between {
		-ms-flex-pack:justify;
	// "space-around" => "distribute"
	 } @else if $just == space-around {
		-ms-flex-pack:distribute;
	 }
	justify-content:$just;
}
@mixin flex-align($align) {
	$align:flex-start !default;
	-webkit-align-items:$align;
	// "flex-start" => "start"
	@if $align == flex-start {
		-ms-flex-align:start;
	// "flex-end" => "end";
	 } @else if $align == flex-end {
		-ms-flex-align:end;
	// "center" => "center"
	 } @else if $align == center {
		-ms-flex-align:center;
	// "stretch" => "stretch"
	 } @else if $align == stretch {
		-ms-flex-align:stretch;
	 }
	align-items:$align;
}
@mixin set-flex($grow, $shrink, $basis) {
	$grow:0 !default;
	$shrink:0 !default;
	$basis:auto !default;
	-webkit-flex:$grow $shrink $basis;
	-ms-flex:$grow $shrink $basis;
	flex:$grow $shrink $basis;
}
@mixin flex-align-self($align) {
	$align:flex-start !default;
	-webkit-align-self:$align;
	-ms-align-self:$align;
	align-self:$align;
}
//// placeholder selector ////
@mixin set-placeholder-style($property, $value) {
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		#{ $property }:$value;
	 }
	&::-moz-placeholder { /* Firefox 19+ */
		#{ $property }:$value;
	 }
	&:-ms-input-placeholder { /* IE 10+ */
		#{ $property }:$value;
	 }
	&:-moz-placeholder { /* Firefox 18- */
		#{ $property }:$value;
	 }
}
	//// experimental
	@mixin ezco-container() {
		max-width:75rem;
		margin:0 auto;
	 }
	@mixin row($test) {
		content:map-get($test, "just");
		//@include justify-center();
		//@include flex-wrap();
	 }
	@mixin ezco-baserow( $params-map:() ) {
		// TODO:koennte hier "#{ }" helfen? Was tut es genau?
		$base-map:(
			"dir":row,
			"wrap":nowrap,
			"justify":space-between,
			"align":stretch
		);
		$map:map-merge($base-map, $params-map);
		@include display-flex();
		@include flex-direction( map-get($map, "dir") );
		@include flex-wrap( map-get($map, "wrap") );
		@include flex-justify( map-get($map, "justify") );
		@include flex-align( map-get($map, "align") );
	 }
	@mixin ezco-basecol( $params-map:() ) {
		$base-map:(
			"dir":column,
			"wrap":nowrap,
			"justify":flex-start,
			"align":center
		);
		$map:map-merge($base-map, $params-map);
		@include display-flex();
		@include flex-direction( map-get($map, "dir") );
		@include flex-wrap( map-get($map, "wrap") );
		@include flex-justify( map-get($map, "justify") );
		@include flex-align( map-get($map, "align") );
	 }
	//// /experimental
////// /MIXINS //////

////// LAYOUT //////
html,
body {
	font-family: 'Rajdhani', sans-serif;
	color: #000;
	min-height:100%;
	height:100%;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Lato', sans-serif;
/*	font-weight:100; // von der Website */
	font-weight:300;
	line-height: 1.1;
}
h1, h2, h3 {
	margin-top: 1.25rem;
	margin-bottom: 0.625rem;
}
h4, h5, h6 {
/*	margin-top: 1.25rem; // von der Website */
/*	margin-bottom: 1.25rem; // von der Website */
}
h1 {
/*	font-size: 4.375rem; // von der Website */
	font-size: 2.375rem;
	padding-bottom: 1.875rem;
/*	text-transform: uppercase; // von der Website */
	a { color: #333; }
	a:hover { color: #595959; }
}
h2 {
	font-family: 'Rajdhani', sans-serif;
	font-size: 1.5rem;
	font-weight: 500;
	a { color: #333; }
	a:hover { color: #595959; }
}
h3 {
	font-size: 1.875rem;
	a { color: #333; }
	a:hover { color: #595959; }
}
h4 {
	font-size: 1.25rem;
	a { color: #333; }
	a:hover { color: #595959; }
}
h5 {
	font-size: 1.1875rem;
	a { color: #333; }
	a:hover { color: #595959; }
}
h6 {
	font-size: 1.125rem;
	a { color: #333; }
	a:hover { color: #595959; }
}

p.h1 {
	font-family: 'Lato', sans-serif;
	font-weight: 100;
	font-size:70px;
	text-transform:uppercase;
}


p { margin: 0 0 0.625rem; }

ul { list-style-type:none; }
iframe,
object,
video {
	width:100%;
	max-width:870;
}
/* foundation-fix ?!? */
.button {height:2.4375rem;}

////// Sticky Header //////
#sticky {
	.sticky {
		background-color: white;
		z-index: 10;
	}

	.spacer{
		border-color: black;
		max-width: none;
		margin: 0px;
	}
}
#sticky.fixed {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	background-color:#fff;
	border-bottom:1px solid #000;
	z-index:30;
}
@media screen and (max-width: 39.9375em) {
	#sticky.fixed {
		position:static;
	}
}
////// /Sticky Header //////

#header_wrap {
	padding:0;
	.logo {
		width:362px;
		height:66px;
	}
	.logo-2 {
		margin:0.7rem auto;
		width:56px;
		height:56px;
	}
	.social-media {
		a {
			float:left;
			margin-right:10px;
			width:36px;
			height:36px;
			text-align:center;
			border:1px solid black;
			img.ic-facebook {
				margin:0;
				width:20px;
				height:20px;
			}
			img.ic-xing {
				margin:0;
				width:34px;
				height:34px;
			}
			img.ic-linkedin {
				margin:2px 0 0 0;
				width:26px;
				height:26px;
			}
		}
	}
	div.social-media {
		height:46px;
		position: relative;
		display: block;
		padding: 0.25rem 1rem;
		border: 1px solid #e6e6e6;
		border-bottom:none;
	}

	#menu-dropdown{
		z-index: 100000;
	}

	.header-menu {
		display: flex;
		justify-content:space-between;
		font-family: 'Lato', sans-serif;
		font-size:1.0525rem;
		font-weight: 400;
		text-transform:uppercase;
		.main-menu-container {
			margin-top:0.6rem;
			margin-bottom:0.6rem;
			ul {
				display:table;
				margin:0 auto;
				li {
					float:left;
					a {
						margin:0.6rem 0;
						padding:0 0.5rem;
						line-height:1rem;
						color:#000;
						border-right:1px solid #000;
					}
					> a::after {
						border:0;
					}
					:hover, .selected {
						color:#ec7a24;
					}
				}
				li:last-child {
					a {border-right:0;}
				}
			}
		}
		.link-external {
			a {
				display:block;
				float:right;
				margin-top:0.4rem;
				margin-bottom:0.6rem;
				padding:0.2rem 0.8rem;
				white-space:nowrap;
				border-top:1px solid #000;
				border-bottom:1px solid #000;
				color:#000;
				:hover {
					color:#ec7a24;
				}
			}
			img {
				float:left;
				width:26px;
				height:26px;
				margin-top:0.6rem;
			}
		}
	}
	.main-menu-container-small {
		position:absolute;
		top:0.9375rem;
		right:0.9375rem;
		.button {
			padding:0;
			width:40px;
			height:50px;
			border:0;
			img {
				width:100%;
				height:100%;
				color:#333;
			}
		}
		.dropdown-pane {
			padding:0;
			.menuitem-small {
				.link {
					padding: 0.9375rem 1rem;
					font-size:1rem;
					text-transform:uppercase;
					color:#333;
					&.level-1 {
						border-top:1px solid #ccc;
						&::first-child {
							border-top:none;
						}
					}
					&.is-active {
						font-weight:bold;
					}
					&::after {
						border-color: #666 transparent transparent;
					}
				}
				.link:focus, .link:hover {
					background-color:#fff;
				}
				.social-media a {
					padding:0;
					.ic-facebook {
						margin-top:6px;
					}
				}
				&.newproducts {
					background-color:limegreen;
					a {
						background-color:transparent;
					}
				}
				&.angebote-sale {
					background-color:#FFED00;
					border:1px solid #EC7A24;
				}
			}
		}
/*
		.link {
			padding: 0.9375rem 1rem;
			font-size:1rem;
			text-transform:uppercase;
			color:#333;
			position: relative;
			display: block;
			border: 1px solid #e6e6e6;
			border-bottom: 0;
			line-height: 1;
		}
		.link:last-child {
			border-bottom: 1px solid #e6e6e6;
		}
		.accordion-content {
			padding:0;
			ul {
				margin:0;
			}
			a {
				display:block;
				width:100%;
				padding: 0.375rem 0 0.375rem 1.9375rem;
				font-size:1rem;
				text-transform:uppercase;
				color:#333;
			}
			a.selected {
				font-weight:bold;
			}
		}
*/
	}
}
@media screen and (min-width: 69.9375em) {
	.main-menu-container-small {display:none;}
}
@media screen and (max-width: 69.9375em) {
	.main-menu-container-large {display:none;}
}
@media screen and (max-width: 65.9375em) {
	#header_wrap {
		#main-menu-container ul {
			li {font-size:0.7rem;}
			a {padding:0.3rem 0.25rem;}
		}
		a.link-external {
			font-size:0.7rem;
			padding:0.3rem 0.25rem;
		}
	}
	h1 {font-size:1.6rem;}
}

#content_wrap {
	padding:1rem 0;
}
#side_navi {
	margin:0;
	padding-top:5px;
	padding-bottom:30px;
	li {
		font-family: 'Lato', sans-serif;
		font-size:1.1rem;
		font-weight: 400;
		&.newproducts {
			background-color:limegreen;
			&:hover a {
				color:white;
			}
		}
		&.angebote-sale {
			background-color:#FFED00;
			border:1px solid #EC7A24;
		}
		a {
			display:block;
			padding:0.1rem 0.4rem;
			width:100%;
			color:#000;
			word-wrap: break-word;
			-moz-hyphens: auto;
			-o-hyphens: auto;
			-webkit-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
			&.level-1 {
				font-weight:bold;
			}
		}
		a:hover, .selected {
			color:#ec7a24;
		}
		ul {
			margin:0 0 0.5rem 0;
			&.level-3 {
				margin:0 0 0.5rem 1.2rem;
			}
			li {
				font-weight: 400;
			}
			&.level-4 {
				margin-left:0;
				li {
					a {
						font-size:0.9rem;
						color:#666;
						&:hover,
						&.selected {
							color:#ec7a24;
						}
					}
				}
			}
		}
	}
	> li.level-1 {
		border:1px solid rgba(10, 10, 10, 0.25);
		margin-bottom:0.4rem;
	}
}

#footer_wrap {
	border-top:1px solid #000;
	padding-top:3rem;
	a,
	a:active,
	a:visited {
		color:#000;
	}
	a.subfooter {
		margin:0 0.4rem;
	}
}

#cookie_directive {
	//display:none;
	background-color:#283846;
	position:static;
	bottom:0;
	z-index:99999;
	width:100%;
	padding:15px 0;
	color:#fff;
	.cd_accept { }
	.cd_reject { }
	&[data-ezco-cookie-directive='showing'] {
		display:block;
		position:fixed;
		opacity:0.85;
	 }
	&[data-ezco-cookie-directive='accepted'] {
		display:none;
	 }
	&[data-ezco-cookie-directive='rejected'] {
		display:block;
	 }
}
////// /LAYOUT //////
.artikel_price {
	text-align:right;
	a {color:#000;}
	.price {
		font-size:1.2em;
		font-weight:bold;
		.addition {
			font-size:0.7em;
			font-weight:normal;
		}
	}
	.grundpreis {
		font-size:0.8rem;
		color:#cc0000;
		text-decoration:line-through;
	}
	.basispreis {
		display:block;
		float:right;
/*		clear:both;*/
		font-size:0.8rem;
		font-weight:bold;
	}
	.price_infos {
		display:block;
		float:right;
/*		clear:both;*/
		font-size:0.8rem;
	}
}

.table_staffelpreis {
	padding:0.2rem;
	width:100%;
	border:2px solid $main-primary;
	font-size:1rem;
	font-weight:bold;
	.staffelpreis {
		float:right;
		padding-left:0.2rem;
	}
}

[data-ez-module-lightbox] img:hover {
	cursor: pointer; }

.slider-container {
	position: relative;
	overflow: hidden;
	font-size: 30px;
	.item {
		position: absolute;
		top: 0px;
	}
	.item [data-ez-item="video"] iframe {
		max-width: 100%;
	}
	.slider-thumbs {
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-ms-flex-align: center;
		-webkit-align-items: center;
		-webkit-box-align: center;
		align-items: center;
		position: absolute;
		z-index: 1;
		left: 25%;
		right: 25%;
		bottom: 0px;
		top: 80%;
		justify-content: space-between;
		.icon {
			padding: 5px;
			background-color: black;
			color: white;
			transition: transform 0.2s ease-in-out;
		}
		.icon:hover {
			cursor: pointer;
			transform: scale(1.1);
		}
		.icon {
			width: 25px;
			height: 25px;
		}
		.thumb {
			position: relative;
			.title {
				position: absolute;
				left: calc( -150px / 2 + ( 25px / 2 ));
				bottom: calc( 100% + 15px);
				width: 150px;
				height: 50px;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-ms-flex-align: center;
				-webkit-align-items: center;
				-webkit-box-align: center;
				align-items: center;
				background-color: black;
				opacity: 0.8;
				justify-content: center;
				color: white;
				text-align: center;
				font-weight: bold;
			}
			.title:after {
				content: "";
				position: absolute;
				top: 100%;
				left: calc( ( 150px / 2 ) - 10px);
				border-style: solid;
				border-width: 10px 10px 0px 10px;
				border-color: black transparent transparent transparent;
				opacity: 0.8;
			}
		}
		.thumb:not(:hover) .title {
			display: none;
		}
		.thumb.active {
			background-color: lightskyblue;
		}
	}
	.slider-buttons {
		.previous,
		.next {
			position: absolute;
			top:40%;
			width: calc( 5% - 5px);
			@media screen and (max-width: 39.9375em) {
				width: calc( 10% - 5px);
			}
			height: 20%;
			opacity:0.1;
			z-index: 1;
			.icon {
				padding: 5px;
				background-color: black;
				color: white;
				transition: transform 0.2s ease-in-out;
				&:hover {
					cursor: pointer;
					transform: scale(1.1);
				}
			}
			&:hover {
				cursor: pointer;
				opacity:1;
			}
		}
		.previous {
			left: 5px;
			text-align: left;
		}
		.next {
			right: 5px;
			text-align: right;
		}
	}
}

.slider-panel-inner{
	padding: 1rem;
	height:360px;
	@media screen and (max-width: 39.9375em) {
		height:440px;
	}
	font-size:24px;
	border: 1px solid gray;
	background-color: #fcfcfc;
	img.productimage {
		max-height:182px;
		@media screen and (max-width: 39.9375em) {
			max-height:120px;
			margin:0 auto;
		}
	}
	.headline a {
		display:block;
		height:120px;
		font-weight:bold;
		overflow:hidden;
	}
	.artnr {
		height:40px;
		overflow:hidden;
	}
	.status {
		height:60px;
		overflow:hidden;
	}
	.artikel_price {
		height:40px;
		overflow:hidden;
	}
	.details_link {
		height:40px;
		overflow:hidden;
	}
}


.quantity {
	.button {text-align:center;}
	input {text-align:right;}
	input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {-webkit-appearance: none;}
	input[type=number] {-moz-appearance: textfield;}
}

.basket {}
.button {font-weight:bold;}

.customer {}

.cart {
	.numberofitems {
		padding-left:0.3rem;
		font-weight:normal;
	}
}

.field_menge {
	margin-bottom:10px;
}
.field_menge input {
	text-align:center;
}
////// PAGES //////
#artikel_direkt {
	.texteingabe {
		margin-bottom:0.5rem;
		height:50px;
	}
	hr.artikel_divider {
		margin:0.5rem 0;
	}
	.zubehoer_container {
		.callout {
			height:236px;
			overflow:hidden;
			.image {
				height:164px;
				text-align:center;
				img {
					max-height:160px;
					align:center;
				}
			}
		}
	}
}
#bestellen_erfolg { }
#index {
	&.content {
		padding-left:0;
		padding-right:0;
	 }
	.button {
		border-radius:3px;
		margin-bottom:1rem;
		text-transform:uppercase;
	 }
	h2 {
		font-size:1.4rem;;
		font-weight:bold;
		text-transform:uppercase;
		margin-bottom:0.5rem;
	 }
	.emp_line {
		font-weight:bold;
		text-transform:uppercase;
		margin-bottom:0.5rem;
	 }
	p {
		font-size:0.9rem;
		color:$main-font-color-secondary;
	 }
	.artikel {
		margin-bottom:1rem;
		.hover_wrap {
			position:relative;
			top:50%;
			transform:translateY(-50%);
			.hover_text {
				font-size:1rem;
				font-weight:bold;
				max-width:120px;
	}
			.hover_button {
				margin-top:2rem;
	}
		 }
		.show_on_hover {
			display:none;
			border:1px solid #dddddd;
			background-color:rgba(255, 255, 255, 0.85);
			text-align:center;
		 }
		&:hover .show_on_hover {
			display:block;
		 }
	 }
}
/* ID #offers ist nicht in #index falls die Angebote auch auf anderen Seiten gezeigt werden sollen */
.angebot.status {
	height:50px;
}
#offers {
	.headline a {
		font-size:1.4rem;
		font-weight:bold;
		color:black;
	}
	.productimage {
		width:100%;
	}
	button.orbit-previous, button.orbit-next {background:rgba(102,102,102,0.1);}
	button.orbit-previous:hover, button.orbit-next:hover {background:rgba(102,102,102,0.5);}
}
#kasse {}
.summe_container {
	border-radius:3px;
	border:1px solid lightgray;
}
.summe_container .cell .row:nth-child(odd) {
	padding:5px 0px;
	background-color:white;
}
.summe_container .cell .row:nth-child(even) {
	padding:5px 0px;
	background-color:#F9F9F9;
}
.summe_container .cell .row.header {
	background-color:lightgray;
}
/** kundendaten / login **/
#show_login_form { display:none; }
#show_login_form + #show_login_form_checkbox {
	padding-right:2rem;
	background-image:url(/resources/images/icons/fi-arrow-down.svg);
	background-repeat:no-repeat;
	background-position:95% 50%;
	background-size:1rem;
}
#show_login_form + #show_login_form_checkbox + #login_form {
	clear:both;
	max-height:0;
	overflow:hidden;
	transition:max-height 2s;
}
#show_login_form:checked + #show_login_form_checkbox {
	background-image:url(/resources/images/icons/fi-arrow-up.svg);
}
#show_login_form:checked + #show_login_form_checkbox + #login_form {
	max-height:30rem;
	overflow:hidden;
}
#form_kundendaten label,
#form_kundendaten select,
#form_kundendaten input {
	font-size:18px;
}
#form_kundendaten #pw_change_btn {
	font-size:0.8em;
}
/** /kundendaten / login **/
#lieferanschrift {}
@media only screen and (min-width: 40.063em) and (max-width: 64em) {
#sortierfeld {width:8rem;}
}
////// Sort Table / Select Page //////
#sort_select {}
#select_page {
	width:100%;
	height:40px;
	@include display-flex();
	@include flex-wrap( nowrap );
	@include flex-justify( space-between );
	@include flex-align( stretch );
	.prev_page {
		@include set-flex(0, 0, 40px);
		border:1px solid transparent;
		border-radius:8px 0px 0px 8px;
	 }
	.select_page {
		@include set-flex(1, 0, 60px);
	 }
	.next_page {
		@include set-flex(0, 0, 40px);
		border:1px solid transparent;
		border-radius:0px 8px 8px 0px;
	 }
}
#listing {
	#artikelliste {
		.headline {
			height:76px;
			overflow:hidden;
			font-size:1rem;
			font-weight:bold;
			color:#ec7a24;
		}
		.image {
			margin:0 0.4rem;
			height:130px;
			text-align:center;
			img.img {
				min-width:100px;
				max-height:120px;
			}
		}
		a {
			color:#333;
		}
		.artno {
			font-weight:bold;
		}
		.artikel_price {
			height:24px;
			overflow:hidden;
		}
		.preisinfos {
			font-size:0.7rem;
		}
		.details {}
		.state {}
		.avail {
			img.icon {
				width:20px;
				height:17px;
			}
		}
		td.preisspalte {
			text-align:right;
			width:25%;
		}
	}
	#sortierfeld {
		padding:0rem 0.5rem;
		height:2rem;
		line-height:0.875rem;
		width:10rem;
		}
	.tbl_select .prev_page {
		float:right;
		padding:0rem 1rem;
		height:2rem;
		line-height:0.875rem;
		}
	.tbl_select .select_page {
		padding:0 1.2rem 0 0.5rem;
		height:2rem;
		line-height:0.875rem;
		text-align:right;
		}
	.tbl_select .next_page {
		padding:0rem 1rem;
		height:2rem;
		line-height:0.875rem;
		}
}
@media screen and (max-width: 63.9375em) {
	#listing {
		table#artikelliste {
			td.preisspalte {
				width:100%;
			}
		}
	}
}
#merkliste { }
#merkliste_login { }
#merkliste_reg { }
#warenkorb { }
////// /PAGES //////
#preis_info {
	font-size:0.8rem;
		color:#999;
}

.single_info_page {
	.text {
		padding-left:20px;
		color:#6D8394;
		font-size:12.8px;
		h1 {
			font-size:12.8px;
			color:#444; 
		 }
	 }
	.headline {
		font-size:1.5em;
		font-weight:bold;
	 }
}

////// MODULES //////
// customer.html
#account_details {
	.ezco_dropdown_label {
		border:none;
	 }
}
// ezentrum_snippets/artikel_page/image_loop/
#main_image {
	max-width:400px;
	margin:0 auto;
	.thumbnail {
		display:block;
		float:left;
		margin-right:10px;
		width:90px;
	 }
}
// 100-verfuegbarkeit/
.icon_avail_good {
	display:inline-block;
	height:12px;
	width:12px;
	border:transparent;
	border-radius:50%;
	background-color:#00ff00;
}
.icon_avail_medium {
	display:inline-block;
	height:12px;
	width:12px;
	border:transparent;
	border-radius:50%;
	background-color:#ffff00;
}
.icon_avail_bad {
	display:inline-block;
	height:12px;
	width:12px;
	border:transparent;
	border-radius:50%;
	background-color:#ff0000;
}
////// /MODULES //////

////// OVERRIDES //////
.collapsed_menu {
	.collapsed_menu_expand {
		background-image:url(/resources/images/icons/3_bars.png);
	 }
	&:hover .collapsed_menu_expand {
		background-image:url(/resources/images/icons/3_bars_hover.png), url(/resources/images/icons/3_bars.png);
	 }
}
////// /OVERRIDES //////

/// lieferanschrift ///
#lieferanschrift label {
	line-height:1.3em;
}
#lieferanschrift .radiobutton {
	vertical-align:top;
}
#lieferanschrift_neu_form {
	display:none;
}
#lieferanschrift_neu:checked + #lieferanschrift_neu_form,
#lieferanschrift_neu:checked + .checkboxlabel + #lieferanschrift_neu_form {
	padding:0.625rem;
	display:block;
}
/// /lieferanschrift ///
/// warenkorb-kasse ///

textarea.bestellpostext_kasse {
	margin-bottom: 0;
	padding-left:0;
	border: none;
	box-shadow: none;
	background-color: white;
	resize: none;
}

/// /warenkorb-kasse ///
/// kasse ///
.address ul {
	list-style:none;
}
.address ul > li {
	margin-left:2em;
}
#agb_accept {
	@include display-flex();
	@include flex-wrap( "nowrap" );
	.agb_accept_checkbox {
		@include set-flex(0, 0, auto);
	 }
	.agb_accept_text {
		@include set-flex(1, 0, auto);
		padding-left:8px;
	 }
}

/// /kasse ///
/// kundendaten ///
#form_kundendaten label,
#form_kundendaten select,
#form_kundendaten input {
	font-size:18px;
}
#form_kundendaten #pw_change_btn {
	font-size:0.8em;
}
/// /kundendaten ///


/// kassenbereich breadcrumbs ///
.checkoutpath {
	margin-left:0 !important;
	margin-right:0 !important;
	margin-bottom:10px;
	background:#F5F5F5;
	color:gray;
	>div {padding-left:10px;}
	.active {background-color:#ccc;font-weight:bold;}
	.cell {
		height:23px;
		a {color:#ec7a24;}
	}
}
.checkoutpath .passed {
	background:$main-primary;
	color:black;
}
/// /kassenbereich breadcrumbs ///
/// error_box ///
#error_box {
	width:100%;
	border:1px dashed pink;
	list-style:none;
	padding:10px 15px;
	margin-bottom:1em;
}
#error_box > li {
	margin-left:30px;
}
#error_box a { color:darkred; }
#error_box a.button { color:#fff; }
/// /error_box ///
/// breadcrumbs ///
#breadcrumbs {
	a, .spacer {
		display:block;
		float:left;
		padding:0 1rem 0 0;
	}
}
/// /breadcrumbs ///
/// verfuegbarkeits farben ///
.avail_good {
	color:forestgreen;
	font-size:0.8em;
}
.avail_medium {
	color:orange;
	font-size:0.8em;
}
.avail_bad {
	color:red;
	font-size:0.8em;
}
.reference {
	font-size:0.7rem;
		font-weight:normal;
		vertical-align:super;
}
.lieferzeit {
		display:block;
		color:gray;
		font-size:0.8rem;
}

.password_show_btn{
	background-color: lightgrey;
	padding: 5px;
	border-radius: 5px;
}
.password_show_btn:hover{
	cursor: pointer;
}

/// /verfuegbarkeits farben ///
/// merklisten_buttons ///
.zu_merkliste,
.von_merkliste,
#to_trash {
	cursor:pointer;
	border-radius:5px;
	margin-right:10px;
	height:45px;
}
/// /merklisten_buttons ///

////// SITE WIDE //////
.alert { color:#ec5840; }
ul.alert { list-style:none; }
/* nur supplementa shops */
.category_line {
	background-color:$main-primary;
	color:white;
	font-weight:bold;
	text-transform:uppercase;
	padding:0 10px;
}
////// /SITE WIDE //////
////// Modules //////
// FORM VALIDATOR
@mixin helper-valid( $helper-name ) {
	.password_checker[data-ez-#{$helper-name}="true"] .helper .helper-item.#{$helper-name} { color: #28a745; }
	.password_checker[data-ez-#{$helper-name}="true"] .helper .helper-item.#{$helper-name} .icon.valid-status:before { @include icon-password-strength-status( true ); }
}

@mixin icon-password-strength-status( $status ) {
	content: "\f00c";
	@if $status {
		color: #28a745;
	} @else {
		color: gray;
	}
}

@mixin icon-input-status( $status ) {
	@if $status == "valid" {
		content: "\f00c";
		color: #28a745;
	} @else if $status == "invalid" {
		content: "\f00d";
		color: #dc3545;
	} @else if $status == "required" {
		content: "\f069";
		color: gray;
	}
}

[data-ez-open="true"] {
	display: block !important;
}

[data-ez-open="false"] {
	display: none !important;
}

[data-ez-module-formvalidator] {
	.form_item {
		margin-bottom: 20px;
		* {
			max-width: 100%;
		}
		input,
		select {
			margin: 0px;
			padding-bottom: 0px;
			padding-top: 0px;
			padding-left: 15px;
			border: none;
			outline: 0px;
			box-shadow: none;
			color: black;
			background-color: transparent;
			height: 35px;
			min-width: 2rem;
		}
		input[type="checkbox"],
		input[type="radio"] {
			margin: 8px 0 8px 15px !important;
			height:20px;
		}
		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}
		input:focus,
		input:hover,
		select:focus,
		select:hover {
			background-color: transparent;
		}
		input[disabled],
		select[disabled],
		input[readonly],
		select[readonly] {
			background-color: #e8e6e6;
		}
		input[disabled]:hover,
		select[disabled]:hover {
			cursor: not-allowed;
		}
		.container {
			@include display-flex;
			justify-content: center;
			border: 1px solid gray;
			border-radius:3px;
			input[type=text] {
				border-radius:3px;
			}
			.status {
				width: 30px;
				background-color: #e9ecef;
				border-left: 1px solid #ced4da;
				border-top-right-radius:3px;
				border-bottom-right-radius:3px;
				label {
					position:relative;
					margin:0;
					svg {
						fill:#3E6B1C;
					}
				}
			}
		}
		.feedback {
			display:none;
			font-size: 12px;
			margin-top: 4px;
			color: gray;
		}
		.feedback.valid {
			display: none;
		}
		.icon {
			@include display-flex;
			float:right;
			justify-content: center;
			margin: 0px;
			width: 100%;
			height: 100%;
			align-items: center;
		}
		.icon.valid-status[data-valid-status="required"]:before {
			@include icon-input-status( "required" );
		}
		.icon:before {
			font-size: 14px;
		}
	}
	.form_item[data-ez-valid="false"] {
		.feedback.valid { display: none; }
		.feedback.invalid { display: block; }
		.icon.valid-status:before {
			@include icon-input-status( "invalid" );
		}
		.container {
			border-color: #dc3545;
		}
		.feedback {
			display:block;
		}
	}
	.form_item[data-ez-valid="true"] {
		.feedback.valid { display: block; }
		.feedback.invalid { display: none; }
		.icon.valid-status:before {
			@include icon-input-status( "valid" );
		}
		.container {
			border-color: #28a745;
		}
	}
	.form_item[data-ez-valid] {
		.feedback.valid { color: #28a745; }
		.feedback.invalid { color: #dc3545; }
	}
	.form_item {
		.icon.valid-status[data-valid-status="wait"]:before {
			@include icon-input-status( "wait" );
		}
	}
}

.password_checker {
	position: relative;
	> div {
		width:100%;
		.output_text {
			float:left;
			width: 4rem;
			font-size:0.8rem;
			color:grey;
		}
		.output_bar {
			float:left;
			margin-top:5px;
			width:calc(100% - 4rem);
			height: 10px;
		}
	}
	.helper {
		display: none;
		position: absolute;
		left: 0px;
		right: 0px;
		bottom: calc( 100% + 10px );
		padding:0.9375rem;
		font-size:0.8rem;
		background-color: #f1f1f1;
		border: 1px solid lightgray;
		z-index: 1;
	}
}

.password_checker .helper .helper-item { color: gray; }
.password_checker .helper .helper-item .icon.valid-status:before {
	@include icon-password-strength-status( false );
}
.password_checker input[data-ez-input]:focus + .helper {
	display: block;
}

@include helper-valid( min-length );
@include helper-valid( max-length );
@include helper-valid( repeated-chars );

@mixin helper-valid( $helper-name ) {
	.password_checker[data-ez-#{$helper-name}="true"] .helper .helper-item.#{$helper-name} {
		color: #28a745;
	}
	.password_checker[data-ez-#{$helper-name}="true"] .helper .helper-item.#{$helper-name} .icon.valid-status:before {
		@include icon-password-strength-status( true );
	}
}

[data-ez-module-passwordtoggle]{
	.output{
		svg:hover{
			fill: $primary-color;
		}
	}
}

[data-ez-module-totop] {
	width:2rem;
	height:2rem;
	border-radius:50%;
	i {
		margin-top:0.4rem;
		font-size:1.2rem;
	}
}
////// /Modules //////

////// Klaro Consent Management //////
#cmd-fingerprint {
	position: fixed;
	bottom: 20px;
	left: 20px;
	width: 50px;
	height: 50px;
	background-color:rgba(204,204,204,0.8);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	a {
		img {
			width: 33px;
			height: auto;
		}
	}
}
.klaro {
	.cookie-modal .cm-modal {
		.cm-header p a {color:#ec7a24;}
		.cm-body ul.cm-apps li.cm-app label.cm-app-label span.cm-app-title {color:white;}
		.cm-footer .cm-footer-buttons button.cm-btn.cm-btn-success.cm-btn-accept-all {background-color:#ec7a24;}
	}
	.cookie-notice .cn-body p.cn-ok {
		button.cm-btn.cm-btn-sm.cm-btn-success {background-color:#ec7a24;}
		a.cm-link.cm-learn-more {color:#ec7a24;}
	}
}
////// /Klaro Consent Management //////

